<template>
    <div style="width: 100%; margin-bottom: 20px">
        <div class="back-img">
            <div style="font-size: 50px; color: #fff">POSTER ONLINE</div>
        </div>

        <div class="text-zone">
            <p style="font-weight: bold; font-size: large;text-align: center">{{ session }} </p>
            <!--            <el-row v-for="i in 10" :key="i" style="padding: 10px">-->
            <!--                <a href="Home.vue">1-iSPEC 2021CP0008 Research on the investment policy of energy storage and other flexible adjustment resources under the scenario of high proportion of new energy</a>-->
            <!--            </el-row>-->
            <el-table
                    :data="tableData"
                    :show-header="false"
                    style="width: 100%">
                <el-table-column prop="name">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="100">
                    <template slot-scope="scope">
<!--                        <i class="el-icon-download"></i>-->
                        <a :href="sessionDir+scope.row.link" target="_blank"> 下载</a>
<!--                        <el-button @click="handleClick(scope.row)" type="text" size="small" icon="el-icon-download">下载</el-button>-->
                    </template>
                </el-table-column>
            </el-table>
        </div>

    </div>
</template>

<script>
    import {PosterData} from './config'
    export default {
        name: "Poster",
        data() {
            return {
                posterDir: process.env.BASE_URL+ '/poster/',
                tableData: [],
                session: '',
                index: null
            }
        },

        beforeRouteUpdate(to, from, next) {
            // react to route changes...
            // don't forget to call next()
            console.log(to, from)
            this.getData(this.parseIndex(to))
            next()
        },
        created() {
            let index = this.parseIndex(this.$route)
            console.log('id', this.$route.query.id, index)
            this.index = index
            this.getData(index)
        },
        computed:{
            sessionDir(){
                return this.posterDir + this.session + '/'
            }
        },
        methods: {
            handleClick(row) {
                console.log(row);
            },
            parseIndex(to){
                return Number(to.query.id.split('-')[1]) - 1
            },
            getData(index){
                this.session = PosterData[index].session
                this.tableData = PosterData[index]['files']
            }
        }
    }
</script>

<style lang="scss">
    .back-img {
        background-image: url("../assets/images/committe/bcg.png");
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .text-zone {
        margin: 30px 100px auto;
        width: 80%;
    }

    .el-table--enable-row-hover .el-table__body tr:hover > td {
        color: #11A3CA;
    }

    .el-table__row {
        padding-bottom: 50px;
    }
</style>